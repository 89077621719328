import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/html5-api-local-storage-geolocation-offline-cache-manifest",
  "date": "2015-07-31",
  "title": "HTML5 API – LOCAL STORAGE, GEOLOCATION & OFFLINE CACHE MANIFEST",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Local storage is more secure, and large amounts of data can be stored locally, without affecting website performance. Unlike cookies, the storage limit is far larger (at least 5MB) and information is never transferred to the server."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Message type="info" title="Web is stateless" content="The main problem with HTTP as the main transport layer of the Web is that it is stateless.
  This means that when you use an application and then close it, its state will be reset the next time you open it." mdxType="Message" />
    <p>{`Normally, this is done server-side, and you would check the username to know which state to revert to. But what if you don’t want to force people to sign up?
This is where local storage comes in. You would keep a key on the user’s computer and read it out when the user returns.`}</p>
    <h3>{`USING LOCAL STORAGE IN HTML5-CAPABLE BROWSERS`}</h3>
    <p>{`To use localStorage, all you have to do is modify the localStorage object in JavaScript.`}</p>
    <p>{`You can do that directly or (and this is probably cleaner) use the `}<inlineCode parentName="p">{`setItem()`}</inlineCode>{` and `}<inlineCode parentName="p">{`getItem()`}</inlineCode>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`localStorage.setItem('favoriteColor','vlue');
`}</code></pre>
    <p>{`If you read out the 'favoriteColor' key, you will get back “blue”:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var color = localStorage.getItem('favoriteColor');  // -> “blue"
`}</code></pre>
    <p>{`To remove the item, you can use — can you guess? — the removeItem() method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`localStorage.removeItem('favoriteColor');
var color = localStorage.getItem('favoriteColor');  // -> null
`}</code></pre>
    <p>{`That’s it! You can also use sessionStorage instead of localStorage if you want the data to be maintained only until the browser window closes.`}</p>
    <h2>{`GEOLOCATION`}</h2>
    <p>{`Geolocation is the process or technique of identifying the geographical location of a person or device by means of digital information processed via the Internet.`}</p>
    <p>{`The HTML Geolocation API is used to get the geographical position of a user. Since this can compromise user privacy, the position is not available unless the user approves it.`}</p>
    <h3>{`USING HTML GEOLOCATION`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`getCurrentPosition()`}</inlineCode>{` method to get the user's position. The example below is a simple Geolocation example returning the latitude and longitude of the user's position:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var x = document.getElementById(“demo");
function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  } else {
    x.innerHTML = "Geolocation is not supported by this browser.";
  }
}
function showPosition(position) {
  x.innerHTML = "Latitude: " + position.coords.latitude +
  "<br>Longitude: " + position.coords.longitude;
￼}
`}</code></pre>
    <h3>{`DISPLAYING THE RESULT IN A MAP`}</h3>
    <p>{`To display the result in a map, you need access to a map service that can use latitude and longitude, like Google Maps:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function showPosition(position) {
 var latlon = position.coords.latitude + "," + position.coords.longitude;
 var img_url = "http://maps.googleapis.com/maps/api/staticmap?center=
         "+latlon+"&zoom=14&size=400x300&sensor=false";
         document.getElementById("mapholder").innerHTML =
                                               "<img src='"+img_url+"'>";
}
`}</code></pre>
    <p>{`In the example above we use the returned latitude and longitude data to show the location in a Google map.`}</p>
    <h2>{`OFFLINE CACHE MANIFEST`}</h2>
    <h3>{`What is Application Cache?`}</h3>
    <p>{`HTML5 introduces application cache, which means that a web application is cached, and accessible without an internet connection.`}</p>
    <h3>{`OFFLINE CACHE MANIFEST`}</h3>
    <p>{`Application cache gives an application three advantages:`}</p>
    <ul>
      <li parentName="ul">{`Offline browsing - users can use the application when they're offline`}</li>
      <li parentName="ul">{`Speed - cached resources load faster`}</li>
      <li parentName="ul">{`Reduced server load - the browser will only download updated/changed resources
from the server`}</li>
    </ul>
    <h3>{`CACHE MANIFEST BASICS`}</h3>
    <p>{`To enable application cache, include the manifest attribute in the document's `}{`<`}{`html`}{`>`}{` tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<!DOCTYPE HTML>
<html manifest="demo.appcache">
...
</html>
`}</code></pre>
    <p>{`Every page with the manifest attribute specified will be cached when the user visits it. If the manifest attribute is not specified, the page will not be cached (unless the page is specified directly in the manifest file).`}</p>
    <p>{`The recommended file extension for manifest files is: `}<inlineCode parentName="p">{`.appcache`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      